import { createHttp } from "../utils/http-common";

const controller = "discountcode";

const getAllDiscountCodeAsync = (branchId, accountId) => {
  const http = createHttp();
  return http.get(
    `/${controller}/get-all-discount-code-in-branch?branchId=${branchId ?? ""}&accountId=${
      accountId ?? ""
    }&IsStoreWeb=true`,
  );
};

const redeemDiscountCodeAsync = (data) => {
  const http = createHttp();
  return http.post(`/${controller}/redeem-discount-code`, data);
};

const getAllDiscountCodeByAccountIdAsync = (branchId, accountId) => {
  const http = createHttp();
  return http.get(
    `/${controller}/get-discount-code-by-account-id?branchId=${branchId ?? ""}&accountId=${accountId ?? ""}`,
  );
};

const getDiscountCodesByAccountIdAsync = (branchId, accountId, orderType) => {
  const http = createHttp();
  return http.get(
    `/${controller}/?branchId=${branchId ?? ""}&accountId=${accountId ?? ""}&orderType=${orderType ?? ""}`,
  );
};

const getDiscountCodeDetailByIdAsync = (storeId, discountCodeId) => {
  const http = createHttp();
  return http.get(
    `/${controller}/get-discount-code-detail-by-id?storeId=${storeId ?? ""}&discountCodeId=${discountCodeId ?? ""}`,
  );
};

const updateCutomersForCutomerSegmentAsync = (data) => {
  const http = createHttp();
  return http.put(`/${controller}/update-cutomers-for-cutomer-segment`, data);
};

const discountCodeDataService = {
  getAllDiscountCodeAsync,
  redeemDiscountCodeAsync,
  getAllDiscountCodeByAccountIdAsync,
  getDiscountCodeDetailByIdAsync,
  getDiscountCodesByAccountIdAsync,
  updateCutomersForCutomerSegmentAsync
};

export default discountCodeDataService;
